<template>
  <div
    class="text-accent-color text-xs leading-base-sm font-medium flex gap-2.5 items-center"
  >
    <button
      @click="route && router.push({ name: route })"
      class="cursor-pointer font-bold md:font-semibold"
    >
      {{ text }}
    </button>

    <ChevronRightIcon
      class="w-2.5 h-2.5 stroke-accent-color"
      style="stroke-width: 4"
    />
  </div>
</template>

<script lang="ts" setup>
import { ChevronRightIcon } from "@heroicons/vue/outline";

const props = defineProps<{
  text: string;
  route?: any;
}>();

const router = useRouter();
</script>
